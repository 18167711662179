import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  background-color: rgb(0 0 0 / 50%);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9998;
  display: flex;
`;

export const Container = styled.div`
  max-width: 480px;
  z-index: 9999;
  background: linear-gradient(180deg, #ffffff 0%, #fafbfc 49.14%, #f5f7f9 100%);
  border: 1px solid #ccd2e3;
  box-sizing: border-box;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px;
  position: relative;
  margin: auto;

  @media (max-width: 500px) {
    width: 90%;
  }
`;

export const Body = styled.div`
  font-family: Neuzeit Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #001c72;

  & a {
    color: #bc2e3e !important;
  }
`;

export const CloseButton = styled.div`
  color: #99a4c7;
  border-radius: 100%;
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
  font-size: 40px;
  font-family: Neuzeit Grotesk, serif;
  display: flex;
  align-items: center;
  & > span {
    display: block;
    margin: auto;
  }
`;

export const KrystalImage = styled.img`
  display: block;
  margin: 40px auto 0;
`;

export const Title = styled.h3`
  font-family: Utopia Std, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: #bc2e3e;
  margin-top: 10px;
  margin-bottom: 10px;
`;
