import React from 'react';
import * as S from './SubPopup.styles';

export const SubPopup = ({
  show,
  setShowSubPopup,
  faqURL,
  pageDesign,
  version
}) => {
  const lang = {
    title: '',
    line1: '',
    line2: '',
    linkText: ''
  };
  switch (version) {
    case 'german':
      Object.assign(lang, {
        title: 'Sie müssen eine Pause von Ihrem Abonnement einlegen?',
        line1:
          'Sie können sich einfach in Ihr Konto auf unserer Website einloggen, um Ihr Abonnement jederzeit zu verwalten, zu ändern oder zu kündigen!',
        line2:
          'Weitere Einzelheiten zu unseren Kündigungsbedingungen für Abonnements oder zur Funktionsweise unseres Kundenkontos finden Sie in unseren FAQ',
        linkText: 'hier'
      });
      break;
    case 'spanish':
      Object.assign(lang, {
        title: '¿Necesita descansar de su suscripción?',
        line1:
          'Sólo tiene que acceder a su cuenta en nuestro sitio web para gestionar, cambiar o cancelar su suscripción en cualquier momento.',
        line2:
          'Si desea conocer más detalles sobre nuestra política de cancelación de suscripciones o sobre el funcionamiento de nuestra cuenta de cliente, consulte nuestras preguntas frecuentes',
        linkText: 'aquí'
      });
      break;
    case 'french':
      Object.assign(lang, {
        title: 'Vous voulez mettre votre abonnement en pause?',
        line1:
          'Il faut juste vous connecter à votre compte sur note site pour gérer,changer ou annuler votre abonnement n’importe quand!',
        line2:
          'Pour plus de détails concernant notre politique  d’annulation d’abonnements, ou comment fonctionne votre compte client, visiter notre section de questions et réponses',
        linkText: 'ici'
      });
      break;
    default:
      Object.assign(lang, {
        title: 'Need to take a break from your subscription?',
        line1:
          'You can simply sign in to your account on our website to manage, change or cancel your subscription!',
        line2:
          'For more details of our subscription cancellation policy, or how our customer account works, checkout our FAQ',
        linkText: 'here'
      });
      break;
  }

  const faqLink = faqURL ? faqURL : 'https://thepetlabco.com/help-center';
  return (
    show && (
      <S.Wrapper>
        <S.Container pageDesign={pageDesign}>
          <S.CloseButton
            pageDesign={pageDesign}
            onClick={() => setShowSubPopup(false)}
          >
            <span>&times;</span>
          </S.CloseButton>
          <S.KrystalImage
            src="/images/krystal-sleeping.svg"
            alt="Krystal Image"
          />
          <S.Title>{lang?.title}</S.Title>
          <S.Body pageDesign={pageDesign}>
            <p>{lang?.line1}</p>
            <p>
              {lang?.line2}{' '}
              <a href={faqLink} target="_blank" rel="noreferrer">
                {lang?.linkText}
              </a>
              .
            </p>
          </S.Body>
        </S.Container>
      </S.Wrapper>
    )
  );
};

export default SubPopup;
